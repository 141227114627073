<template>
  <el-card>
    <div class="flex items-start justify-between gap-3 fs-14 font-normal">
      <div class="w-100">
        <div class="grid grid-cols-4 gap-1 w-100">
          <p class="fs-16 font-semibold uppercase mb-0">{{ healthRecord && healthRecord.person && healthRecord.person.name }}</p>
          <p>
            <span>{{ healthRecord && healthRecord.person && healthRecord.person.gender === 1 ? "Nam" : "Nữ" }},</span>
            <span>{{ formatYear(healthRecord && healthRecord.person && healthRecord.person.birthday) }}</span>
            <span>({{ mdtUtils.getAges(new Date(healthRecord && healthRecord.person && healthRecord.person.birthday)) }})</span>
          </p>
          <p class="mb-0">
            SĐT:
            <b>{{ (healthRecord && healthRecord.person && healthRecord.person.phone) || "--" }}</b>
          </p>
          <p class="mb-0">
            Địa chỉ:
            <b>{{ this.getAddress(healthRecord && healthRecord.person) || "--" }}</b>
          </p>
          <p class="mb-0">
            CCCD:
            <b>{{ (healthRecord && healthRecord.person && healthRecord.person.cmnd) || "--" }}</b>
          </p>
          <p class="mb-0">
            BHYT:
            <b>{{ (healthRecord && healthRecord.person && healthRecord.person.insurance_number) || "--" }}</b>
          </p>
          <p class="mb-0">
            Mã bệnh nhân (PID):
            <b>{{ (healthRecord && healthRecord.person && healthRecord.person.pid) || "--" }}</b>
          </p>
        </div>
        <hr class="my-3" v-if="!!(healthRecord && healthRecord.start_time)" />
        <div class="grid grid-cols-3 gap-1 w-100" v-if="!!(healthRecord && healthRecord.start_time)">
          <p class="mb-0">
            Ngày khám:
            <b>{{ healthRecord.start_time ? appUtils.formatDateTime(healthRecord.start_time, { format: "HH:mm DD/MM/YYYY", isUTC: true, isLocal: true }) : "--" }}</b>
          </p>
          <p class="mb-0">
            Bác sĩ:
            <b>{{ (healthRecord && healthRecord.doctor && healthRecord.doctor.User && healthRecord.doctor.User.name) || "--" }}</b>
          </p>
          <p class="mb-0">
            Phòng khám:
            <b>{{ (healthRecord && healthRecord.clinic && healthRecord.clinic.name) || "--" }}</b>
          </p>
        </div>
      </div>
      <div>
        <el-tooltip class="item" effect="dark" content="Chỉnh sửa thông tin bệnh nhân" placement="top">
          <img @click="showModalUpdatePatient" style="width: 20px; max-width: unset" class="cursor-pointer" src="/images/icon-edit.svg" alt />
        </el-tooltip>
      </div>
    </div>
    <ModalPatientInfo ref="ModalPatientInfo" @refresh="getPersonInfo" />
  </el-card>
</template>
<script>
import ModalPatientInfo from '@/components/Patient/ModalPatientInfo.vue'
import appUtils from '@/utils/appUtils'
import mdtUtils from '@/utils/mdtUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import moment from 'moment'
export default {
  name: 'PatientInfo',
  mixins: [mixinGetPersonInfo],
  components: { ModalPatientInfo },
  props: ['healthRecord'],
  data () {
    return {
      appUtils,
      mdtUtils
    }
  },
  methods: {
    formatYear (date) {
      return date && moment(date).format('YYYY')
    },
    formatDateDMY (date) {
      return date && moment(date).utc().format('HH:mm DD/MM/YYYY')
    },
    showModalUpdatePatient () {
      this.$refs.ModalPatientInfo.openDialog(true, this.$t('lbl_edit_patient'), this.healthRecord?.person)
    },
    getPersonInfo () {
      this.$emit('refresh')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
