<template>
  <div v-if="patientVisit" class="p-3">
    <div>
      <div class="circle mb-2" @click="goBack">
        <i class="el-icon-back m-auto text-2xl" />
      </div>
      <PatientInfo :healthRecord="patientVisit" v-loading="loading" @refresh="refresh"></PatientInfo>
    </div>
    <div>
      <div class="mt-4">
        <SelectDiseaseICD10V2 v-loading="loading" :dataIcd="diseaseICD" :disabled="true" />
      </div>
      <div class="mt-4">
        <p class="mb-1 font-semibold uppercase">Phiếu khám</p>
        <ConsulationTempV2
          v-loading="loading"
          :patient-visit="patientVisit"
          @refresh="getPatientVisit"
        />
      </div>
      <div class="mt-4">
        <p class="mb-1 font-semibold uppercase">Đơn thuốc</p>
        <PrescriptionTempV2
          v-loading="loading"
          :patient-visit="patientVisit"
          @refresh="getPatientVisit"
        />
      </div>
      <div class="mt-4">
        <p class="mb-1 font-semibold uppercase">Xét nghiệm</p>
        <indication-temp-v2
          v-loading="loading"
          :name="$t('Xét nghiệm')"
          :type="INDICATION_TYPES.CODE.TEST_FORM"
          :consultation="consultationSms"
          :patient-visit="patientVisit"
          @refresh="getPatientVisit"
        />
      </div>
      <div class="mt-4">
        <p class="mb-1 font-semibold uppercase">Chẩn đoán hình ảnh</p>
        <indication-temp-v2
          v-loading="loading"
          :name="$t('Chẩn đoán hình ảnh')"
          :type="INDICATION_TYPES.CODE.ULTRASOUND"
          :consultation="consultationSms"
          :patient-visit="patientVisit"
          @refresh="getPatientVisit"
        />
      </div>
      <div class="mt-4">
        <p class="mb-1 font-semibold uppercase">Chỉ định khác</p>
        <indication-temp-v2
          v-loading="loading"
          :name="$t('Chỉ định khác')"
          :type="INDICATION_TYPES.CODE.OTHERS"
          :consultation="consultationSms"
          :patient-visit="patientVisit"
          @refresh="getPatientVisit"
        />
      </div>
      <div class="mt-4 mb-4">
        <p class="mb-1 font-semibold uppercase">Sinh học phân tử</p>
        <indication-temp-v2
          v-loading="loading"
          :name="$t('Sinh học phân tử')"
          :type="INDICATION_TYPES.CODE.MOLECULAR_BIOLOGY"
          :consultation="consultationSms"
          :patient-visit="patientVisit"
          @refresh="getPatientVisit"
        />
      </div>
    </div>
  </div>
</template>
  <script>
import ConsulationTempV2 from '@/components/PatientVisitHealthRecord/ConsulationTempV2.vue'
import statusAppt from '@/constants/statusAppt'
import appUtils from '@/utils/appUtils'
import { APPOINTMENT_METHOD_STATUS, EXAMINATION_STATUS, INDICATION_TYPES } from '@/utils/constants'
import ModalSelectHR from '@components/Appointment/ModalSelectHR.vue'
import IndicationTempV2 from '@components/PatientVisitHealthRecord/IndicationTempV2.vue'
import OnlineRoom from '@components/PatientVisitHealthRecord/OnlineRoomTemp.vue'
import PrescriptionTempV2 from '@components/PatientVisitHealthRecord/PrescriptionTempV2.vue'
import UploadFile from '@components/PatientVisitHealthRecord/UploadFilesTemp.vue'
import SelectDiseaseICD10V2 from '@components/SelectDiseaseICD10V2/index.vue'
import ModalAddConsultation from '@pages/HealthRecords/ModalAddConsultation.vue'
import PhieuKhamBenhDetail from '@pages/HealthRecords/PhieuKhamBenh/ModalAdd.vue'
import ModalViewConsultation from '@pages/HealthRecords/PhieuKhamBenh/ViewPhieuKham.vue'
import { BIcon, BOverlay } from 'bootstrap-vue'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import PatientInfo from '../components/PatientInfo.vue'

export default {
  name: 'PatientVisitDetailV2',
  mixins: [mixinGetPersonInfo],
  components: {
    BIcon,
    BOverlay,
    ConsulationTempV2,
    ModalSelectHR,
    ModalAddConsultation,
    PhieuKhamBenhDetail,
    ModalViewConsultation,
    IndicationTempV2,
    PrescriptionTempV2,
    OnlineRoom,
    UploadFile,
    SelectDiseaseICD10V2,
    PatientInfo
  },
  computed: {
    isHasEHCSync () {
      const settings = appUtils.isJSON(this.$globalClinic?.settings) ? JSON.parse(this.$globalClinic?.settings) : {}
      return !!settings?.ehc_config
    }
  },
  data () {
    return {
      loadingSendResult: false,
      aleartSendResult: false,
      loading: false,
      DialogVisibleStatus: false,
      consultationSms: {},
      root_type: 1,
      appointment: null,
      clinic_id: null,
      doctor_id: null,
      request_id: null,
      request: null,
      person: null,
      person_id: null,
      appt_edit: null,
      selectedHr: null,
      statusAppt,
      valueStatus: '',
      patientVisit: null,
      INDICATION_TYPES,
      showExtendButton: false,
      EXAMINATION_STATUS,
      timer: null,
      listIndicationNonResults: [],
      APPOINTMENT_METHOD_STATUS,
      diseaseICD: {},
      isSyncing: false
    }
  },
  created () {
    const self = this

    if (!self.$route.params?.id) {
      self.$router.push({ path: '/doctor/appointment-management' })
      return
    }

    self.getPatientVisit()

    self.timer = setInterval(function () {
      self.getPatientVisit()
    }, 1000 * 30) // refetch 30s
  },
  mounted () {
    const self = this

    // let user = this.$user
    self.clinic_id = this.$clinicId
  },
  destroyed () {
    this.$bus.off('change:status')
    this.cancelAutoUpdate()
  },
  watch: {
    patientVisit (data) {
      this.patientVisit = data
    }
  },
  methods: {
    handeCloseAleartSendResult () {
      this.aleartSendResult = false
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
    async getPatientVisit () {
      const self = this
      self.loading = true

      try {
        const id = self.$route.params?.id
        const result = await self.$rf.getRequest('DoctorRequest').getDetailPatientVisit(id)

        if (result?.data) {
          self.patientVisit = result.data
          self.diseaseICD = {
            code: result.data?.icd_code || '',
            name: result.data?.icd_name || ''
          }
          self.consultationSms = {
            id: self.patientVisit?.appt?.consultation?.id,
            patient_visit_id: self.patientVisit?.id,
            health_record_id: self.patientVisit?.appt?.consultation?.health_record_id,
            indication_order: self.patientVisit?.indication_order
          }
          self.checkingIndicationFullResult(self.patientVisit?.indication_order)
        }
      } catch (error) {
        console.log('', error)
      } finally {
        self.loading = false
      }
    },
    checkingIndicationFullResult (orders) {
      if (!orders) return

      this.listIndicationNonResults = []

      /* eslint-disable no-unused-expressions */
      orders?.map(order => {
        order?.indication?.map(item => {
          if (!item?.examination_results) {
            this.listIndicationNonResults.push(item)
          }
        })
      })

      return !!this.listIndicationNonResults.length
    },
    getApptStatus (stt) {
      switch (stt) {
        case EXAMINATION_STATUS.CODE.WAITING:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
        case EXAMINATION_STATUS.CODE.WAITING_RESULT:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Chờ kết quả'
          }
        case EXAMINATION_STATUS.CODE.BEING:
          return {
            class: 'text-green-600 border-green-600 bg-green-50',
            text: 'Đang khám'
          }
        case EXAMINATION_STATUS.CODE.DONE:
          return {
            class: 'text-blue-600 border-blue-600 bg-blue-50',
            text: 'Đã khám xong'
          }
        case EXAMINATION_STATUS.CODE.CANCELED:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Đã hủy lượt'
          }
        case EXAMINATION_STATUS.CODE.WAITING_READ_RESULT:
          return {
            class: 'text-indigo-600 border-indigo-600 bg-indigo-50',
            text: 'Chờ đọc kết quả'
          }
        case EXAMINATION_STATUS.CODE.MISSING:
          return {
            class: 'text-gray-600 border-gray-600 bg-gray-50',
            text: 'Nhỡ lượt'
          }
        default:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
      }
    },
    async getConsultationById (id) {
      try {
        const self = this
        await this.$rf
          .getRequest('DoctorRequest')
          .getConsultationByIDV2(id)
          .then(res => {
            self.consultationSms = res.data
            console.log(self.consultationSms)
          })
      } catch (error) {
        console.log('', error)
      }
    },
    async sendResult () {
      try {
        if (!this.patientVisit.id) return
        const params = {
          patient_visit_id: this.patientVisit.id
        }
        this.loadingSendResult = true
        await this.$rf
          .getRequest('DoctorRequest')
          .sendResultPatientVisit(params)
          .then(res => {
            console.log(res)
            this.$message({
              message: 'Gửi kết quả thành công',
              type: 'success'
            })
            this.loadingSendResult = false
            this.aleartSendResult = false
          })
      } catch (error) {
        console.log('', error)
        this.loadingSendResult = false
        this.aleartSendResult = false
      }
    },
    goBack () {
      this.$router.go(-1)
      // this.$router.push({ path: '/doctor/patient-visit' })
    },
    getTime (date_time) {
      return window
        .moment(date_time)
        .utc()
        .format('HH:mm:ss')
    },
    formatDateDMY (date_time) {
      return (
        date_time &&
        window
          .moment(date_time)
          .utc()
          .format('DD/MM/YYYY')
      )
    },
    getPersonInfoString (p) {
      if (!p.birthday) return ''

      return this.formatDateDMY(p.birthday) + ' (' + this.getAges(p.birthday) + ')' + (p.career ? ', ' + p.career : '')
    },
    getGender (gender = 1) {
      return gender === 1 ? this.$t('schedule_page.lbl_male') : gender === 2 ? this.$t('schedule_page.lbl_female') : gender === 3 ? this.$t('schedule_page.lbl_other') : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return age + this.$t('schedule_page.lbl_age')
    },
    getStatusAppt (status) {
      if (status === undefined || status === null) return ''
      return this.statusAppt[status]
    },
    async updateStatusPatient (stt) {
      let _confirm = null

      if (stt === EXAMINATION_STATUS.CODE.MISSING && (this.patientVisit.status === EXAMINATION_STATUS.CODE.BEING || this.patientVisit.status === EXAMINATION_STATUS.CODE.WAITING_READ_RESULT)) {
        _confirm = await this.$confirm(this.$t('Bạn chắc chắn muốn bỏ qua lượt khám này?'), this.$t('Cảnh báo'), {
          dangerouslyUseHTMLString: true
        }).catch(_ => {})

        if (!_confirm) return
      }

      if (stt === EXAMINATION_STATUS.CODE.DONE) {
        _confirm = await this.$confirm(this.$t('Kết thúc lượt khám cho bệnh nhân này?'), this.$t('Cảnh báo'), {
          dangerouslyUseHTMLString: true
        }).catch(_ => {})

        if (!_confirm) return

        this.showExtendButton = false
      }

      this.patientVisit.status = stt
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updatePatientVisitsById(this.patientVisit.id, this.patientVisit)
        .then(res => {
          this.loading = false
          if (this.patientVisit.status === EXAMINATION_STATUS.CODE.MISSING) {
            this.$router.push({
              name: 'PatientVisitManagement',
              query: {
                tab: EXAMINATION_STATUS.NAME.MISSING.toLowerCase().replace('_', '-')
              }
            })
          }
          // this.getPatientVisit()
        })
        .catch(e => {
          console.log(e)
        })
    },
    async handleSyncEHC () {
      const self = this
      const person = this.patientVisit?.person

      if (!this.patientVisit?.id || !person?.id) return

      try {
        self.isSyncing = true
        const params = {
          org_id: self.$globalClinic?.org_id,
          ws_id: self.$globalClinic?.id,
          person_id: person?.id,
          medical_record_ref_id: person?.ref_id,
          patient_visit_id: this.patientVisit?.id
        }

        await self.$rf
          .getRequest('EHCSyncRequest')
          .ehcSyncIndicationHealthRecords(params)
          .then(res => {
            if (!res?.data) return
            if (res?.data?.code === 401) {
              self.$message({
                type: 'error',
                message: self.$t(`Bạn không thể đồng bộ dữ liệu từ EHC`)
              })
              console.log(res)
              return
            }
            self.getPatientVisit()
            self.$message({
              type: 'success',
              message: self.$t(`Đồng bộ dữ liệu thành công`)
            })
            self.isSyncing = false
          })
      } catch (error) {
        console.log(error)
      }
    },
    refresh () {
      this.getPatientVisit()
    }
  }
}
</script>
  <style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#appointment_detail_img {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.appointment-person-info .person-name {
  padding-left: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.appointment-person-info .person-avatar .avatar {
  --tblr-avatar-size: 64px;
}

.header-title {
  width: calc(100% - 32px);
}

.btn-c {
  background-color: #20419b;
  color: white;
  border: none;
}

.btn-d {
  background-color: #1a80fd;
  color: white;
  border: none;
}

.btn-drop-shadow {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.6);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  &:active {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.6);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  &-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    &:active {
      --tw-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }
}

.hodo-table table {
  border-collapse: collapse !important;
  width: 100% !important;
  border: 1px solid #dbdde0 !important;
  margin-top: 1rem !important;
}

.hodo-table table thead th {
  text-transform: uppercase !important;
}

.hodo-table table th,
.hodo-table table td {
  padding: 8px !important;
  border: 1px solid #dbdde0 !important;
}
.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #52575c;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
  