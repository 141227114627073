<template>
  <div class="p-3 txt-grey-900 fs-14 fw-500">
    <PatientInfo :healthRecord="healthRecord" v-loading="loading" @refresh="refresh"></PatientInfo>
    <label class="mt-4">Danh sách lượt khám</label>
    <el-card class="mt-2">
      <el-table class="w-full" :data="listPatientVisits" @row-click="viewDetail" row-class-name="cursor-pointer" v-loading="loading_table">
        <el-table-column>
          <template slot="header">Ngày khám</template>
          <template slot-scope="scope">
            <span class>{{ appUtils.formatDateTime(scope.row.start_time, { format: "HH:mm DD/MM/YYYY", isUTC: true, isLocal: true }) }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">Bác sĩ khám</template>
          <template slot-scope="scope">
            <span>
              {{ (scope.row.doctor && scope.row.doctor.User && scope.row.doctor.User.name) || scope.row.doctor_ref_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">Phòng khám</template>
          <template slot-scope="scope">
            <span class>
              {{ scope.row.consulting_room && scope.row.consulting_room.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            {{ $t("health_book_page.lbl_hd_status") }}
          </template>
          <template slot-scope="scope">
            <div class="ml-auto" :set="(status = getApptStatus(scope.row.status))">
              <div :class="status.class" class="border rounded-full">
                <div class="py-1 px-2 text-center">{{ status.text }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex justify-end items-center w-full pt-2">
        <el-pagination background layout="prev, pager, next" :pager-count="5" :page-size="Number(meta.page_size)" :current-page.sync="current_page" :total="meta.total" class="el-pagination-mdt" @next-click="handleChangePage" @prev-click="handleChangePage" @current-change="handleChangePage" hide-on-single-page></el-pagination>
      </div>
    </el-card>
  </div>
</template>

  <script>
import Pagination from '@/components/Pagination'
import router from '@/router'
import { EXAMINATION_STATUS } from '@/utils/constants'
import { BPagination } from 'bootstrap-vue'
import moment from 'moment'
// import { debounce } from 'lodash'
import appUtils from '@/utils/appUtils'
import PatientInfo from './components/PatientInfo.vue'

export default {
  components: { BPagination, Pagination, PatientInfo },
  name: 'ListPatientVisitV2',
  data () {
    return {
      appUtils,
      listPatientVisits: [],
      meta: {},
      healthRecord: {},
      valueSearch: '',
      current_page: 1,
      params: {
        page: 1
      },
      statuses: [
        { id: 0, name: 'Chờ khám', class: 'text-warning' },
        { id: 1, name: 'Đang khám', class: 'text-warning' },
        { id: 2, name: 'Có kết luận', class: 'text-success' },
        { id: 3, name: 'Đã trả sổ ', class: 'text-danger' }
      ],
      loading: false,
      loading_table: false
    }
  },
  async created () {
    await this.getListPatientVisits()
    await this.getDetailHealRecord()
  },
  // computed: {
  //   listPatientVisits: function () {
  //     return this.listPatientVisits
  //   }
  // },
  methods: {
    getApptStatus (stt) {
      switch (stt) {
        case EXAMINATION_STATUS.CODE.WAITING:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
        case EXAMINATION_STATUS.CODE.WAITING_RESULT:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Chờ kết quả'
          }
        case EXAMINATION_STATUS.CODE.BEING:
          return {
            class: 'text-green-600 border-green-600 bg-green-50',
            text: 'Đang khám'
          }
        case EXAMINATION_STATUS.CODE.DONE:
          return {
            class: 'text-blue-600 border-blue-600 bg-blue-50',
            text: 'Đã khám xong'
          }
        case EXAMINATION_STATUS.CODE.CANCELED:
          return {
            class: 'text-red-600 border-red-600 bg-red-50',
            text: 'Đã hủy lượt'
          }
        case EXAMINATION_STATUS.CODE.WAITING_READ_RESULT:
          return {
            class: 'text-indigo-600 border-indigo-600 bg-indigo-50',
            text: 'Chờ đọc kết quả'
          }
        case EXAMINATION_STATUS.CODE.MISSING:
          return {
            class: 'text-gray-600 border-gray-600 bg-gray-50',
            text: 'Nhỡ lượt'
          }
        default:
          return {
            class: 'text-yellow-600 border-yellow-600 bg-yellow-50',
            text: 'Chờ khám'
          }
      }
    },
    async getListPatientVisits () {
      try {
        this.loading_table = true
        const param = {
          health_record_id: this.$route.params.id,
          page_num: this.current_page,
          sort_by: 'start_time',
          order: 'desc'
        }
        if (this.valueSearch) param.search = this.valueSearch
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getPatientVisitsV2(param)
          .then(res => {
            this.listPatientVisits = res?.data?.data
            return res.data
          })

        this.meta = res?.page
        return res
      } catch (e) {
        console.log(e)
      } finally {
        this.loading_table = false
      }
    },
    async handleChangePage (newPage) {
      this.current_page = newPage
      await this.getListPatientVisits()
    },
    async getDetailHealRecord () {
      try {
        this.loading = true
        const id = parseInt(this.$route.params.id)
        const results = await this.$rf
          .getRequest('DoctorRequest')
          .getHealthRecordByID(id)
          .then(res => {
            // this.listPatientVisits = res?.data
            this.healthRecord = res.data
            return res?.data
          })
        return results
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    formatDateDMY (date) {
      return date && moment(date).utc().format('HH:mm DD/MM/YYYY')
    },
    formatYear (date) {
      return date && moment(date).format('YYYY')
    },
    getStatus (index) {
      if (!index) return this.statuses[0]?.name
      return this.statuses[index]?.name
    },
    viewDetail (obj) {
      router.push({ path: `/health-records/list-patient/detail/${obj.id}` })

      // this.$refs.ModalViewHR.openDialog(obj)
    },
    viewDetailById (obj) {
      this.$refs.ModalViewHR.openDialog(obj)
    },
    refresh () {
      this.getDetailHealRecord()
    }
  }
}
</script>

  <style scoped></style>
